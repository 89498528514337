@import "../../../styles/common.styles.scss";


.user-dashboard-top-widgets {
  display: flex;
  justify-content: space-around;
  background-color: $primary-color;
  padding: 30px 0px;

  .user-dashboard-card-container {
    min-width: 20vw;
  }
}

.user-dashboard-charts-area {
  margin-top: 20px;
  margin-bottom: 20px;

  .user-dashbaord-card {
    @include shadow;
    background-color: #fff;
    min-height: 100px;
    text-align: center;
    border-radius: 5px;
    animation: move-up 1s ease;
    width: 96vw;
    margin: 0vh 2vw 0vh 2vw;

    .card-title {
      text-align: left;
      letter-spacing: 1px;
      font-size: .8rem;
      color: $blue-color;
      margin-bottom: 20px;
      padding: 5px 10px;
      text-transform: uppercase;
      background-color: #fff;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: space-between;

      .card-info:hover {
        cursor: pointer;
      }
    }

    .chart-section {
      padding: 0px 20px 20px 20px;
    }

    .subject-list {
      display: flex;
      font-size: .8rem;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 10px;

      .subject-button {
        background-color: $yellow-color;
        color: $blue-color;
        padding: 2px 7px;
        border-radius: 20px;
        letter-spacing: 1px;
        margin-bottom: 5px;
        margin-right: 5px;
      }

      .subject-button:hover {
        cursor: pointer;
      }

      .active-button {
        background-color: $purple-color;
        color: #fff;
      }
    }

    .redeem-section {
      font-size: .7rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding: 1vw;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      ;

      .redeem-view-all {
        margin-top: 10px;
        text-align: right;
        letter-spacing: 1px;
        color: $blue-color;
        margin-right: 20px;
        font-weight: bold;
      }

      .redeem-view-all:hover {
        cursor: pointer;
        color: $purple-color;
      }

      .redeem-item {
        width: 25vw;
        border: 1px solid #ccc;
        padding: 0px 0px 5px 0px;
        border-radius: 2vw;
        margin-bottom: 10px;

        .redeem-item-value {
          color: $purple-color;
        }

        img {
          width: 100%;
          border-top-left-radius: 2vw;
          border-top-right-radius: 2vw;
        }
      }

      .redeem-item:hover {
        @include shadow;
        cursor: pointer;
        margin-top: -10px;
        // box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.4);
        transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
      }
    }

    .quizzes-section {
      padding: 0px 20px;
      margin-bottom: 20px;
    }

    .background-yellow {
      background-color: $yellow-color;
      color: $blue-color;
    }

  }


}

.user-dashboard-quiz-area {
  margin-top: 20px;

  .user-dashboard-quiz-title {
    @include main-heading
  }

  .user-dashboard-quiz-list {
    width: 100%;
    text-align: center;

    .quiz-title {
      text-align: left;
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 1.2rem;
      color: $purple-color;
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }
}


// Media queries
@media only screen and (max-width: 767px) {
  .user-dashbaord-top-widget-card {
    padding: 5px;
    min-height: 20px;
    text-align: center;

    .top-widget-value {
      font-size: 1rem;
    }

    .top-widget-bottom {
      text-align: center;

      .top-widget-label {
        text-align: center;
        font-size: .7rem;
        width: 100%;
      }

      .top-widget-image {
        display: none;

        img {
          width: 24px;
        }
      }
    }
  }
}