@import '../../../styles/common.styles.scss';

.game-console-jump {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;

  .game-console-jump-background {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;

    /*adjust s value for speed*/
    img {
      height: 100vh;
    }

    .remove-gap {
      margin-left: -15px;
    }
  }


  .game-console-jump-path {
    background-color: burlywood;
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 7vh;
  }



  .image-flipped {
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
  }

  .background-jump-animation {
    animation: game-console-jump-animatedBackground 30s linear infinite;
  }

  .game-console-jump-current-score {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    font-weight: bold;
    font-size: 1.2rem;
    color: #fff;
    text-shadow: 2px 2px #000000;
    align-items: center;
    display: flex;

    img {
      width: 24px;
      margin-right: 5px;
    }
  }

  .game-console-jump-runner {
    width: 120px;
    position: absolute;
    bottom: 10px;
    left: 150px;

    img {
      width: 120px;
    }

    .game-console-jump-runner-props {
      width: 120px;
      height: auto;
    }

  }

  .bonus-point {
    left: 200px;
    bottom: 100px;
    animation: bonus-point 1s linear;

    img {
      width: 32px;
    }
  }

  @keyframes bonus-point {
    from {
      -webkit-transform: rotateX(0deg);
      -moz-transform: rotateX(0deg);
      transform: rotateX(0deg);
      bottom: 100px;
    }

    to {
      -webkit-transform: rotateX(720deg);
      -moz-transform: rotateX(720deg);
      transform: rotateX(720deg);
      bottom: 200px;
    }
  }

  .jump-runner-jumping {
    animation: jump-jump 2s;
  }

  @keyframes jump-jump {

    50% {
      bottom: 130px;
    }

    100% {
      bottom: 10px;
    }
  }

  .game-console-jump-obstacles {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    left: 500px;

    img {
      height: 50px;
    }
  }

  .jump-obstacle-animation {
    animation: jump-block 6s infinite linear;
  }

  @keyframes jump-block {
    0% {
      left: 110vw;
    }

    100% {
      left: -200px
    }
  }

  .hide-jump-obstacle {
    visibility: hidden;
  }


  .game-console-jump-start-game-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    .game-console-jump-scoreboard-image {
      position: relative;
      text-align: center;

      img {
        height: 90vh;
      }

      .game-console-jump-scorecard-info {
        position: absolute;
        top: 40px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 1px;
        height: 60vh;

        img {
          width: 7vw;
          height: auto;
          margin-top: 30px;
        }

        .game-console-jump-scorecard-title {
          font-family: 'Comic Neue';
        }

        .game-console-jump-game-info {
          width: 100%;

          img {
            width: 50vh;
          }
        }

        .game-console-jump-scores {
          width: 70%;
          font-size: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    .game-console-jump-play-button {
      text-align: center;

      img {
        height: 20vh;
        margin-top: -20vh;
      }
    }
  }

}

@keyframes game-console-jump-animatedBackground {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-63.16%, 0);
  }
}