@import '../../../styles/common.styles.scss';

.user-dashbaord-top-widget-card {
  @include shadow;
  background-color: #fff;
  padding: 10px 20px;
  min-height: 75px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 10px;
  animation: zoom-in-zoom-out 1s ease;

  .top-widget-value {
    font-size: 2rem;
  }

  .top-widget-bottom {
    display: flex;
    justify-content: space-between;

    .top-widget-label {
      letter-spacing: 1px;
    }

    .top-widget-image {
      position: absolute;
      top: 5px;
      right: 5px;

      img {
        width: 50px;
      }
    }
  }
}

.blue-card {
  background: linear-gradient(45deg, #189dd8, #52c4f7) !important;
}

.purple-card {
  background: linear-gradient(45deg, #800080, #de3ade);
}

.yellow-card {
  background: linear-gradient(45deg, #f8a735, #fdb551);
}

.red-card {
  background: linear-gradient(45deg, #e30d7d, #ff63b5);
}

.green-card {
  background: linear-gradient(45deg, #7aa805, #aade26);
}