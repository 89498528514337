@import "../../../styles/common.styles.scss";

.user-dtl-container {
  @include shadow;
  background-color: #fff;
  text-align: center;
  margin: 10px;
  display: flex;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  animation: move-up 1s ease;
  .total-count {
    min-width: 150px;
    margin: 10px;
    vertical-align: bottom;
    display: inline-block;
    .total-label {
        font-size: 1.2rem;
        color: $purple-color;
        font-weight: bold;
        letter-spacing: 1px;
    }

    .total-value {
        font-size: 1.2rem;
        color: #333;
        font-weight: bold;
        letter-spacing: 1px;
    }
  }
.user-top-widgets{
  padding: 15px;
  min-height: 20px;
  text-align: center;
  font-weight: bold;
  .kid-title{
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 1.5rem;
    color: $blue-color;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-bottom: 15px;
  }
  .blue-text {
    color: #189dd8;
  }

  .purple-text {
    color: #800080;
  }

  .red-text {
    color:#e30d7d;
  }

  .green-text {
    color: #7aa805;
  }

  .yellow-text {
    color: #FFB64D;
  }
}
  .badge {
    @include shadow;
    background-color: #fff;
    min-height: 100px;
    text-align: center;
    animation: move-up 1s ease;
    font-weight: bold;
    .badge-grade{
      color: $blue-color;
    }
    .badge-badge{
      color: $purple-color;
    }
  }
  .user-dtl-list {
    min-height: 300px;

    table {
      min-width: 300px !important;
    }
  }

  .no-orders-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    color: $gray-color;
    margin-bottom: 20px;

    img {
      width: 300px;
    }
  }

  .user-dtl-container-pagination {
    padding: 20px 0px;
    border-top: 1px solid #ddd;

    .MuiPagination-ul {
      justify-content: center;
    }
  }
}
