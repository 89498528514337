@import "../../styles/common.styles.scss";

.class-selection-container {
  background-color: $yellow-color;
  height: 100%;
  width: 100%;
  min-height: 100vh;

  .class-selection-title {
    padding-top: 70px;
    text-align: center;
    border-bottom: 1px solid $purple-color;
    padding-bottom: 10px;
  }

  .class-selection-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 20px;

    .class-selection-item {
      background-color: #f60;
      font-size: 1.1rem;
      padding: 3px 10px;
      border-radius: 20%;
      margin: 20px 3vw;
      border: 2px solid #fff;
      box-shadow: 1px 2px 5px #777;
      min-width: 20vw;
      text-align: center;
    }

    .class-selection-item-active {
      background-color: $green-color;
      color: #fff;
    }

  }

  .class-selection-save-button {
    text-align: center;
    margin-top: 50px;

    span {
      background-color: #fff;
      padding: 3px 55px;
      font-size: 1.3rem;
      margin-top: 50px;
      letter-spacing: 1px;
      border: 2px solid #fff;
      background-color: $purple-color;
      box-shadow: 1px 2px 7px #777;
      color: #fff;
    }

    span:hover {
      background-color: #fff;
      color: $purple-color;
    }
  }


}