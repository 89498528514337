@import "../../../styles/common.styles.scss";

.contest-status-main {
  @include shadow;
  margin: 20px;
  padding: 15px;
  border-top: 10px solid $purple-color;
  background-color: #fff;
  border-radius: 10px;

  .contest-status-title {
    @include main-heading;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .contest-status-buttons {
    button {
      margin-left: 5px;
    }
  }
}