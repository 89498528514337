@import '../../../styles/common.styles.scss';

.infinite-game-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.infinite-game-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}

.infinite-game-buttons{
  position: absolute;
  top: 1vw;
  right: 1vw;
  display: flex;
  align-items: center;

  .infinite-game-music-button {
    width: 6vw;
    z-index: 1;
  
    img {
      width: 100%;
    }
  }
  
  .infinite-game-exit-button {
    width: 6vw;
    z-index: 1;
  
    img {
      width: 100%;
    }
  }
}


.infinite-game-portrait {
  display: none;
}

@media (orientation: portrait) {
  .infinite-game-container {
    display: none;
  }

  .infinite-game-loader {
    display: none;
  }

  .infinite-game-portrait {
    display: block;
    height: 100vh;
    text-align: center;
    letter-spacing: 1px;
    padding-top: 20vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: .9rem;

    img {
      margin-top: 20px;
      width: 75px;
    }
  }
}