@import '../../styles/common.styles.scss';

.quiz-card-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 9px 10px rgba(86, 124, 173, .25);
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  position: relative;

  .quiz-card-coming-soon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: .7rem;
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
  }

  .quizTitle {
    letter-spacing: 1px;
    padding: 10px;
    padding-bottom: 5px;
    font-size: .7rem;
    text-align: center;
    color: #555;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 40px;
  }

  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  p {
    margin: 0;
    padding: 5px 0;
    letter-spacing: 1px;
    font-size: .9rem;
    text-align: center;
    color: $purple-color;
    font-weight: bold;
  }
}

.quiz-card-container:hover {
  transform: scale(1.05);
}