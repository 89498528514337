@import '../../../styles/common.styles.scss';

.admin-feedback {
  .supportContainer {
    width: 100%;
    min-height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;

    .faqList {

      margin: 10px;

      .heading {
        flex-shrink: 0;
        font-size: .8rem;
        letter-spacing: 1px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .headingName {
          color: $purple-color;
        }

        .headingEmail {
          color: $gray-color;
        }

        .headingDate {
          color: #666;
        }
      }

      .message {
        letter-spacing: 1px;
        font-size: .8rem;
        color: $gray-color;
        text-align: justify;
      }
    }
  }

  .supportContainerMobile {
    width: 100vw;
    min-height: 300px;
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
  }
}