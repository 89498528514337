@import '../../styles/common.styles.scss';

.my-story-listing-page {
  @include plainBackground;
  min-height: 100vh;
  padding-top: 10vh;

  .my-story-top-section {
    width: 40vw;
    margin-left: 30vw;
  }

  .my-story-create-new-section {
    margin: 0px 3vw;
    padding-bottom: 3px;
    letter-spacing: 1px;
    color: $purple-color;
    border-bottom: 1px solid #333;

  }

  .my-story-gender-selection {
    display: flex;
    justify-content: space-evenly;
    position: absolute;
    top: 10px;
    right: 10px;

    .my-story-gender-card {
      display: flex;
      padding: 1vw 2vw;
      justify-content: space-between;
      background-color: #fff;
      width: 20vw;
      border-radius: 10vw;
      margin-left: 3vw;

      img {
        width: 7vw;
      }
    }
  }

  .my-story-listing {
    display: flex;
    justify-content: space-evenly;
    padding-top: 30px;
    padding-bottom: 20px;
    flex-wrap: wrap;

    .my-story-card {
      @include shadow;
      width: 45vw;
      height: 35vw;
      background-color: #fef;
      color: #6d3ea2;
      border-radius: 10px;
      margin-bottom: 30px;
      position: relative;

      .my-story-story-selected {
        position: absolute;
        top: 1vw;
        left: 1vw;
        width: 7vw;
        height: 7vw;
        background-color: rgba(0, 0, 0, .6);
        border-radius: 7vw;

        img {
          width: 7vw;
        }
      }

      .my-story-image {
        height: 28vw;
        overflow: hidden;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        img {
          width: 100%;
        }
      }

      .my-story-title {
        height: 7vw;
        font-size: .8rem;
        letter-spacing: .5px;
        padding: 0px 3px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .existing-stories {
    justify-content: start;

    .my-story-card {
      width: 28vw;
      height: 28vw;
      margin-left: 4vw;

      .my-story-image {
        height: 22vw;
      }

      .my-story-title {
        height: 6vw;
        font-size: .6rem;
      }
    }
  }

  .my-story-form {
    padding-top: 20px;
    padding-bottom: 50px;

    .my-story-form-field {
      width: 80vw;
      margin-left: 10vw;
      margin-bottom: 30px;

      .MuiInput-formControl {
        width: 80vw;
        color: #a15d98 !important;
      }

      .MuiFormLabel-root {
        color: #a15d98 !important;
      }
    }

    .my-story-form-button {
      text-align: center;
      width: 100vw;
      padding-top: 30px;

      span {
        color: #fff;
        background-color: #a15d98;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 1.4rem;
        letter-spacing: 1px;

        box-shadow: 1px 2px 7px #777;
      }
    }
  }
}