
@import '../../styles/common.styles.scss';

.invoice {
    .wrapper {
        width: 100%;
        background-color: #fff;

        .payments {
            display: flex;
            justify-content: center;
            letter-spacing: 1px;

            .payments-form {
                width: 50%;

                .line-break {
                    margin: 5px 0;
                    height: 5px;
                    border-bottom: 1px solid #ccc;
                }

                .payments-title {

                    font-size: 1.5rem;
                    font-weight: bold;
                    color: #004AAD;
                    text-align: center;
                    margin-bottom: 30px;
                    margin-top: 10px;
                    border-bottom: 1px solid #ccc;
                }

                .formLabel {
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;
                    letter-spacing: 1px;

                    .paymentLabel {
                        width: 50%;
                        color:$gray-color;
                        font-weight: bold;
                        overflow-wrap: anywhere;
                    }

                    .paymentValue {
                        width: 50%;
                        text-align: right;
                        color: #333;
                        font-weight: bold;
                        overflow-wrap: anywhere;
                    }
                }
            }
        }
    }

    .invoiceButtonContainer {
        text-align: center;

        .purchaseButton {
            background-color: #004AAD;
            width: 200px;
            margin-top: 20px;
            margin-bottom: 20px;
            padding: 10px;
            color: #ffffff;
            font-weight: bold;
            letter-spacing: 2px;
            text-transform: uppercase;
            border-radius: 5px;
        }
    }
}

.invoicemobile {
    .wrapper {
        .payments {
            .payments-form {
                width: 95%;
            }
        }
    }
}

@media print {
    body * {
        visibility: hidden;
    }

    #section-to-print,
    #section-to-print * {
        visibility: visible;
    }

    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
    }

    #no-print {
        visibility: hidden;
    }
}