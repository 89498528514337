@import "../../../styles/common.styles.scss";

.games-selection-page {
    @include plainBackground;
    min-height: 100vh;

    .games-selection-list {
        display: flex;
        height: 90vh;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        .games-selection-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            letter-spacing: 1px;

            .games-selection-image {
                display: flex;
                justify-content: center;
                .games-selection-animation {
                    width: 50vw;
                }
            }

            .games-selection-button {
                color: $primary-color;
                width: 50vw;
                padding: 10px 5px;
                text-align: center;
                text-transform: uppercase;
                font-size: 1.2rem;
                letter-spacing: 1px;
                border-bottom: 1px solid $purple-color;
            }

            .games-selection-filters {
                padding: 0px 0px 10px 0px;
                display: flex;
                justify-content: space-around;
                font-size: 0.7rem;
                z-index: 1;

                .games-selection-filter-button {
                    padding: 5px 13px;
                    margin-left: 5px;
                    background-color: $yellow-color;
                    border-radius: 10px;
                    letter-spacing: 1px;
                    margin-top: 10px;
                    @include shadow;
                }

                .active-grade {
                    background-color: $purple-color;
                    color: #fff;
                }

                .games-selection-filter-button:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
