@import '../../../styles/common.styles.scss';

.scorecard-page {
  @include page-container;
  padding: 20px 20px;

  .attempt-row {
    animation: zoom-in-zoom-out 1s ease;
    padding: 10px 5px;
    letter-spacing: 1px;
    font-size: .9rem;

    span {
      padding: 2px 10px;
      background-color: $yellow-color;
      color: $blue-color;
      border-radius: 50px;
    }

    span:hover {
      background-color: $purple-color;
      color: #fff;
      cursor: pointer;
    }
  }


  .scorecard-previous-attempts {
    @include shadow;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: calc(100vh - 180px);
    overflow-y: auto;
    margin-left: -10px;
    border-radius: 10px;
    position: relative;

    .attempts-image {
      position: absolute;
      bottom: 0;
      right: 0;
      opacity: .2;
    }

    .previous-attempt-subject {
      font-size: .9rem;
      color: $purple-color;
      letter-spacing: 1px;
      margin-bottom: 10px;
      text-align: right;
      font-weight: bold;
    }

    .previous-attempt-header {
      text-align: right;
      color:$gray-color;
      font-size: .8rem;
      letter-spacing: 1px;
    }

    .previous-attempt-list-header {
      letter-spacing: 1px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }

  }

  .scorecard-image {

    animation: zoom-in-zoom-out 1s ease;

    img {
      width: 90%;
    }
  }

  .scorecardContainer {
    @include shadow;
    min-height: 300px;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    // border: 3px solid #eee;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    animation: zoom-in-zoom-out 1s ease;


    .scorecard-top-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ccc;
      background-color: $yellow-color;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;

      .scorecard-kid-details {
        display: flex;
        align-items: center;
        margin: 10px;
        border: 1px solid #ccc;
        justify-content: flex-start;
        padding: 5px 10px;
        border-radius: 50px;
        background-color: #fff;

        .scorecard-avatar {
          line-height: 1;

          img {
            width: 30px;
          }
        }

        .scorecard-kid-name {
          letter-spacing: 1px;
          margin-left: 10px;
        }
      }

      .scorecard-score {
        background-color: $purple-color;
        padding: 5px 10px;
        color: #fff;
        border-radius: 50px;
      }
    }

    .answersTitle {
      font-size: 1.5rem;
      color: $blue-color;
      letter-spacing: 1px;

      p {
        font-size: 0.8rem;
        margin-top: 5px;
        color:$gray-color;
      }
    }

    .view-all-answers {
      text-align: right;
      margin-right: 20px;
      letter-spacing: 1px;
      width: 100%;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      span {
        background-color: $blue-color;
        color: #fff;
        padding: 5px 10px;
        margin: 2%;
        border-radius: 20px;
      }

      span:hover {
        cursor: pointer;
      }
    }

    .question {
      display: inline-block;
      color: #FFFFFF;
      width: 30px;
      border-radius: 50%;
      padding: 5px;
      margin: 5px;

      &:hover {
        transform: scale(1.05);
        cursor: pointer;
      }
    }

    .correctAnswer {
      background-color: #3eb489;
    }

    .wrongAnswer {
      background-color: $red-color;
    }

    .Title {
      color: $red-color;
      padding: 15px;
      margin: 0;
      background-color: #fdc453;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      color: #004AAD;
      font-weight: bold;
    }

    .TitleSmall {
      display: flex;
      margin-top: 10px;
      color: #004AAD;
      font-size: 1rem;
    }

    .totalQuestions {
      font-size: 1.1rem;
      padding: 10px;
      background-color: #004AAD;
      color: #ffffff;
      font-weight: bold;
    }

    .scorecard-middle-area {
      padding: 20px;
      padding-top: 0px;

      .scorecard-result-area {
        text-align: center;

        .scorecard-result {

          .scorecard-percentage {
            color: $purple-color;
            font-size: 4rem;
            font-weight: bold;
            letter-spacing: 2px;
          }

          .stars {
            .scoreboard-star-on {
              animation: dance 2s ease infinite;
            }

            img {
              width: 50px;
              margin: 7px;
            }
          }
        }
      }

      .scorecard-subject-details {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        background-color: $purple-color;
        padding: 10px;
        border-radius: 10px;

        .scorecard-label {
          color: $yellow-color;
          letter-spacing: 1px;
        }

        .scorecard-value {
          color: #fff;
          letter-spacing: 1px;
        }
      }
    }

    .backScorecard {
      text-align: right;
      padding: 10px;
      border-bottom: 1px solid #ddd;

      button {
        background-color: $red-color;
        color: #ffffff;
      }
    }

    .questions {
      padding: 10px 20px;
      text-align: left;
      letter-spacing: 1px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;

      .label {
        color: $red-color;
      }

      .questionImage {
        text-align: center;
        margin-top: 20px;
      }

      .answerLabel {
        color: $green-color;
      }

      .breaker {
        border-bottom: 1px solid #ddd;
        width: 80%;
        margin-left: 10%;
        margin-top: 20px;
      }
    }

  }

  .backButton {
    // width: 60vw;// margin-left: 20vw;
    text-align: right;
    margin-top: 20px;

    span {
      border-bottom: 3px solid $red-color;
      color: #004AAD;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 10px;
    }

    span:hover {
      background-color: #004AAD;
      color: #ffffff;
      cursor: pointer;
    }
  }


  // Mobile styling
  .scorecardContainerMobile {
    width: 100vw;
    min-height: 300px;
    margin-left: -40px;
    text-align: center;
    margin-top: 10px;
    border-radius: 0;

    .scorecard-subject-details {
      font-size: .8rem;

      .scorecard-label {
        font-weight: bold;
      }
    }

    .scorecard-top-area {
      border-radius: 0;
    }

    .Title {
      border-radius: 0;
      padding: 5px;
    }

    .TitleSmall {
      font-size: .8rem;
    }

    .scorecardContent {
      .leftArea {
        .avatar {
          margin-top: 10px;

          img {
            width: 25%
          }
        }
      }

      .rightArea {
        text-align: center;

        .scores {
          .label {
            font-size: 1rem;
          }

          .scoreNumbers {
            font-size: 4rem;
          }
        }
      }
    }
  }

  .backButtonMobile {
    width: 100vw;
    margin-right: 5px;
    text-align: right;
    margin-top: 10px;

    span {
      color: #004AAD;
      font-weight: bold;
      letter-spacing: 1px;
      padding: 5px;
    }

    span:hover {
      background-color: #004AAD;
      color: #ffffff;
      cursor: pointer;
    }
  }

}