@import '../../styles/common.styles.scss';

.transactions-container {
  @include shadow;
  background-color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  animation: move-up 1s ease;

  .transactions-list {
    min-height: 300px;

    table {
      min-width: 300px !important;
    }
  }

  .no-transactions-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1px;
    color: $gray-color;
    margin-bottom: 20px;

    img {
      width: 300px;
    }
  }

  .transactions-container-pagination {
    padding: 20px 0px;
    border-top: 1px solid #ddd;

    .MuiPagination-ul {
      justify-content: center;
    }
  }

}

.supportContainerMobile {
  width: 100vw;
  min-height: 500px;
  text-align: center;
  margin-top: 20px;
  margin-left: 0;
}