@import '../../styles/common.styles.scss';

.sequence-game-container {
  height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow: hidden;

  .sequence-game-loader {
    height: 100%;
    width: 100%;
    background-color: yellowgreen;
  }

  .sequence-game-start-screen {
    height: 100%;
    background-color: yellowgreen;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Patrick Hand', cursive;

    .sequence-game-title {
      font-weight: bolder;
      font-size: 3rem;
      color: yellow;
      letter-spacing: 3px;
      text-shadow: 2px 2px #000;
      text-transform: uppercase;

    }

    .sequence-game-start-image {
      width: 20vw;
    }

    .sequence-game-start-play-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      font-size: 2rem;
      font-weight: bold;
      background-color: green;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .sequence-game-start-play-button:hover {
      cursor: pointer;
    }

    .sequence-game-start-exit-button {
      display: inline-block;
      padding: 1vh 4vh;
      letter-spacing: 4px;
      border-radius: 5vh;
      margin-left: 2vw;
      font-size: 2rem;
      font-weight: bold;
      background-color: red;
      color: yellow;
      box-shadow: 1px 2px 5px #555;
      border: 3px solid yellow;
    }

    .sequence-game-start-exit-button:hover {
      cursor: pointer;
    }

    .sequence-game-start-music-button {
      width: 5vw;
      margin-top: 2vh;
      background-color: black;
      border-radius: 5vw;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }

  }

  .sequence-game-play-area {
    height: 100%;
    width: 100%;

    .sequence-game-bg {
      height: 100%;
      width: 100%;
      position: absolute;
      overflow: hidden;

      img {
        width: 100%;
        height: 100vh;
      }
    }
    .sequence-game-level-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 7vw;
      color: #fff;
      background-color: $purple-color;
      border-radius: 5vw;
      padding: 2px 12px;
    }

    .sequence-game-music-button {
      position: absolute;
      top: 1vh;
      left: 1vh;
      width: 4vw;
      background-color: black;
      border-radius: 5vw;

      img {
        width: 100%;
      }

      img:hover {
        cursor: pointer;
      }
    }

    .sequence-game-exit-button {
      position: absolute;
      top: 1vh;
      right: 1vh;
      width: 3vw;
      height: 3vw;
      background-color: rgba(255, 0, 0, .9);
      color: white;
      border-radius: 3vw;
      border: 2px solid #000;
      box-shadow: 1px 3px 7px #333;
      display: flex;
      font-size: 1.5rem;
      font-weight: bold;
      justify-content: center;
      align-items: center;
    }

    .sequence-game-exit-button:hover {
      cursor: pointer;
    }

    .sequence-game-list-container {
      position: absolute;
      right: 6vw;
      top: 5vh;
      display: flex;
      align-items: center;
      font-family: 'Patrick Hand', cursive;
      max-width: 50vw;

      .sequence-game-list-backdrop {

        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid yellow;
        border-radius: 1vw;
        width: 100%;

        .sequence-game-list-title {
          letter-spacing: 2px;
          font-weight: bold;
          font-size: 1.2rem;
          letter-spacing: 3px;
          color: yellow;
          text-align: center;
          text-shadow: 2px 2px #000000;
          text-transform: uppercase;
          padding: 0 2vw;
        }

        .sequence-game-list-item {

          display: flex;
          flex-wrap: wrap;
          justify-content: center;


          .sequence-game-list-item-image {
            background-color: #fff;
            height: 20vh;
            width: 20vh;
            margin: 2vh;
            border-radius: 1vh;
            box-shadow: 5px 5px 10px #333;

            img {
              width: 100%;
              border-radius: 1vh;
            }
          }
        }

        .sequence-game-level-clear {
          height: 65vh;
          width: 30vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .sequence-game-level-clear-animation {
            width: 20vw;
            text-align: center;
          }

          .sequence-game-next-level {
            text-align: center;

            .sequence-game-next-level-button {
              margin: 2vh;
              display: inline-block;

              img {
                width: 10vw;
              }

              img:hover {
                cursor: pointer;
              }
            }
          }
        }

      }
    }

    .sequence-game-placeholder-list {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      height: 25vh;
      width: 100%;
      justify-content: space-evenly;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
      overflow: hidden;


      .sequence-game-placeholder-item-image {
        width: 20vh;
        height: 20vh;
        border-radius: 1vh;
        display: flex;

        img {
          width: 100%;
          border-radius: 1vh;
        }

        .sequence-game-placeholder-correct-answer {
          width: 40vh;
          height: 40vh;
          margin-top: -20vh;
          margin-left: -10vh;
          border-radius: 1vh;
          display: flex;
          position: absolute;
        }

        .sequence-game-placeholder-blank-box {
          height: 20vh;
          width: 20vh;
          display: flex;
          justify-content: center;
          background-color: rgba(255, 255, 255, .8);
          border-radius: 1vh;
          border: 3px solid orange;
          align-items: center;
          font-size: 2rem;
          color: grey;
        }

      }

      .sequence-game-placeholder-list-arrow {
        width: 20vh;
        height: 20vh;
        display: flex;

        img {
          width: 100%;
        }
      }

    }

  }


  .sequence-game-animation {
    position: absolute;
    bottom: 25vh;
    left: 2vh;
    width: 30vh;
    background-color: none;
  }
}

.sequence-game-portrait {
  display: none;
}

@media (orientation: portrait) {
  .sequence-game-container {
    display: none;
  }

  .sequence-game-portrait {
    display: block;
    height: 100vh;
    text-align: center;
    letter-spacing: 1px;
    padding-top: 20vh;
    padding-left: 5vw;
    padding-right: 5vw;
    font-size: .9rem;

    img {
      margin-top: 20px;
      width: 75px;
    }
  }
}