@import "../../../styles/common.styles.scss";
.main-container {
  width: 100%;
  margin-top: 20px;
  .contest-header{
    @include shadow;
    margin: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .contest-title {
      @include main-heading;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding-bottom: 10px;
    }
    .header-panel {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      text-align: left;
      .header-field {
        min-width: 150px;
        margin-top: 20px;
        display: inline;
        Button {
          margin-left: 10px;
        }
      }
    }
  }
  
  .contestFormContainer {
    @include shadow;
    margin-left: 20px;
    padding: 15px;
    border-top: 10px solid $purple-color;
    background-color: #fff;
    border-radius: 10px;
    .header-panel {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      text-align: left;
      .header-field {
        min-width: 150px;
        margin-top: 20px;
        display: inline;
        Button {
          margin-left: 10px;
        }
      }
    }
  }
  .icon {
    color: rgb(36, 119, 54);
  }
  .deleteIcon {
    color: rgb(173, 0, 0);
  }
  .priviewIcon {
    color: rgb(58, 53, 87);
  }
  .count-text {
    font-size: x-large;
    color: rgb(36, 119, 54);
    font-weight: bold;
  }
}
