@import '../../../styles/common.styles.scss';

.game-engine {
  padding: 0;
  margin: 0;
  height: 100vh;
  overflow: hidden;

  .game-engine-loader-container {
    background-color: $blue-color;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    height: 100vh;
    overflow: hidden;
    padding: 0;
    margin: 0;

    .game-engine-loader {
      width: 20vw;
    }
  }

  .game-engine-game-info-button {
    position: absolute;
    top: 2vw;
    right: 2vw;

    img {
      width: 3vw;
    }
  }

  .game-engine-game-info-button:hover{
    cursor: pointer;
  }
}


// Css for asking user to change to landscape mode.
@media (orientation: portrait) {
  .game-engine {
    display: none;
  }

  .game-engine-switch-to-landscape {
    margin: 20vh 10vw 10vw 10vw;
    text-align: center;

  }
}

@media (orientation: landscape) {
  .game-engine-switch-to-landscape {
    display: none;
  }
}