@import '../../../../styles/common.styles.scss';
.admin-topic {
  .quizList {
    width: 85vw;
    margin: 0 auto;
  }

  .quizListHeader {
    width: 85vw;
    margin: 0 auto;
    display: flex;
    color: #fdc453;
    font-weight: bold;
    letter-spacing: 2px;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.5rem;
    margin-top: 40px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    margin-bottom: 10px;

    .subjectName {
      color: $red-color;
    }
  }


  .showLoading {
    width: 55px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
}