@import '../../styles/common.styles.scss';

.notification-container {
  @include plainBackground;
  min-height: 100vh;
  padding-bottom: 70px;
  .notification-image {
    img {
      width: 100%;
    }
  }

  .notifications-animation{
    display: flex;
    justify-content: center;
  }

  .notification-container-content {
    min-height: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: transform 0.25s ease-out;

    .notification-title {
      font-size: 1.2rem;
      color: $primary-color;
    }

    .notification-section {

      .TitleSmall {
        color: $blue-color;
        text-align: left;
      }

      .notification-list {

        .notification-card{
          text-align: center;
          padding: 20px 20px 20px 0px;
          border-bottom: 1px solid #ccc;
          display: flex;
          justify-content: flex-start;

          .notification-icon{
            width: 20vw;
            text-align: center;
            img{
              width: 10vw;
            }
          }
          .notification-info{
            width: 80vw;
          .heading {
            flex-shrink: 0;
            font-weight: bold;
            letter-spacing: 1px;
            color: $purple-color;
            text-align: left;
            font-size: 1rem;
          }

          .notification-date {
            letter-spacing: 1px;
            color: $red-color;
            text-align: left;
            font-size: .8rem;
          }

          .notification-answers {
            letter-spacing: 1px;
            color: #555;
            text-align: justify;
            margin-top: 10px;
            font-size: .8rem;
          }

          .delete-icon {
            color: rgb(173, 0, 0);
          }

          }
        }
      }
    }
  }
  .notification-paging{
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }
}


// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .notification-container {
    .notification-container-content {
      .notificationSection {
        font-size: .5rem !important;
      }
    }
  }
}