@import '../../styles/common.styles.scss';

.pricingContainer {
  @include page-container;
  padding-bottom: 70px;
  min-height: 100vh;
  @include plainBackground;

  .pricing-animation {
    display: flex;
    justify-content: center;

    .pricing-package-animation {
      width: 30vw;
    }
  }

  .subscription {
    background-color: #fff;
    @include shadow;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;
    text-align: center;
    margin-left: 5vw;
    margin-right: 5vw;

    .subscription-text {
      letter-spacing: 1px;
      color: $purple-color;
      font-size: .9rem;
      font-weight: normal;
      margin-bottom: 10px;
    }

    .formField {
      .subscription-code{
        width: 40vw;
      }
      Button {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

  .TitleSmall {
    color: $blue-color;

    .taxInfo {
      letter-spacing: 1px;
      color: $gray-color;
      font-size: small;
      text-transform: capitalize;
      font-weight: normal;
    }

    .coupon-Info {
      letter-spacing: 1px;
      font-size: 1rem;
      font-weight: normal;
      text-transform: capitalize;
      margin-top: 10px;

      span {
        font-size: 1.3rem;
        font-weight: bold;
        color: $purple-color;
      }
    }
  }

  .coupon-banner {
    width: 100%;
    min-height: 50px;
    background-color: $blue-color;
    color: #fff;
    font-size: 1.3rem;
    text-align: center;
    padding-top: 15px;
  }

  .package-title {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    letter-spacing: 1px;
    color: $primary-color;
    font-size: .8rem;
  }

  .packagesList {

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;

    .packageCard {
      position: relative;
      width: 45vw;
      text-align: center;
      background-color: #fff;
      // border: 1px solid #ccc;
      @include shadow;
      border-radius: 10px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .packageName {

        font-size: 1.2rem;
        text-align: right;
        padding: 5px 0px;
      }

      .packageImage {
        @include shadow;
        display: inline-block;
        margin-top: -50px;
        background-color: #fff;
        border-radius: 50px;
        margin-bottom: 20px;

        img {
          width: 75px;
          padding: 5px;
        }
      }

      .package-validity {
        position: absolute;
        top: 0;
        right: 0;
        background: $purple-color;
        color: #fff;
        padding: 0px 10px;
        font-size: .7rem;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        letter-spacing: 1px;
      }


      .package-image {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 1.4rem;

        img {
          width: 50%;
        }
      }

      .package-savings {
        position: absolute;
        top:0;
        left: 0;
        background: $green-color;
        color: #fff;
        padding: 0px 10px;
        font-size: .7rem;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;
        letter-spacing: 1px;
      }

      .packageDetails {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 10px;




        .package-name {
          background-color: #eee;
          color: $primary-color;
          width: 100%;
          letter-spacing: 1px;
          text-align: center;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          padding: 5px 0px;
          font-size: 1rem;
        }

        .selected-package-name {
          background-color: $yellow-color;
        }

        .packagePrice {
          padding: 3px 0px;
          background-color: $green-color;
          font-family: -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            'Fira Sans',
            'Droid Sans',
            'Helvetica Neue',
            sans-serif;


          .priceValue {
            letter-spacing: 1px;
            color: #fff;
            font-size: 1rem;
          }

          .discounted {
            color: $gray-color;
            text-decoration: line-through;
            font-size: .7rem;
          }


          .no-strike {
            text-decoration: none;
          }

          .priceDiscount {
            color: $gray-color;
            font-size: .7rem;
          }
        }


        .no-background {
          background-color: transparent;
        }


        .numberQuiz {
          font-size: 1.5rem;
          font-weight: bold;
          letter-spacing: 1px;
          color: $blue-color;
        }

        .buyButton {
          background-color: $primary-color;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
          padding: 10px;
          color: #ffffff;
          font-weight: bold;
          letter-spacing: 2px;
          text-transform: uppercase;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .freeButton {
          background-color: $purple-color;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px;
          padding: 10px;
          color: #ffffff;
          font-weight: bold;
          letter-spacing: 2px;
          text-transform: uppercase;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
        }

        .buyButton:hover {
          color: $yellow-color;
          background-color: $blue-color;
          cursor: pointer;
        }


        .package-features-list {
          margin: 20px 20px;

          .package-feature {
            color: $gray-color;
            border-bottom: 1px solid #ccc;
            padding: 10px 0px;
          }
        }
      }


    }

    // .packageCard:hover {
    //   cursor: pointer;
    //   margin-top: -10px;
    //   box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.4);
    //   transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    // }
  }

  .pricing-buy-button {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    span {
      width: 90vw;
      background-color: $primary-color;
      color: #fff;
      @include shadow;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 10px 5px;
      border-radius: 10px;
      text-align: center;
      font-size: 1.2rem;
    }
  }

  .savings-based-on{
    position:absolute;
    bottom: 0;
    left: 0;
    font-size: .5rem;
    text-align: center;
    width: 100%;
  }
}