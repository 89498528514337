@import '../../styles/common.styles.scss';

.quiz-user-dashboard-listing {
  @include plainBackground;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-evenly;
  padding-bottom: 100px;
  min-height: 100vh;

  .quiz-listing-page-top {
    position: absolute;
    top: 0;
    width: 100vw;

    img {
      width: 100vw;
    }
  }

  .quiz-page-top {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .quiz-page-top-animation {
      width: 70vw;
    }
  }

  .quiz-card {
    background-color: #fff;
    margin: 20px 10px;
    border-radius: 10px;
    width: 38vw;
    @include shadow;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .quiz-name {
      font-size: 1rem;
      color: $purple-color;
      letter-spacing: 1px;
    }

    .quiz-tag {
      font-size: .8rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding-bottom: 5px;

      div {
        color: $red-color;
        font-weight: bold;
      }
    }

  }

  .quiz-card-empty {
    background-color: none !important;
    box-shadow: none;
    margin: 10px;
    border-radius: 10px;
    width: 38vw;
  }

  .quiz-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
}




// Media queries
@media only screen and (max-width: 1023px) {
  .quiz-user-dashboard-listing {
    .quiz-card {
      .quiz-name {
        font-size: .9rem;
      }

      .quiz-tag {
        font-size: .6rem;
      }
    }

    .quiz-card-empty {
      background-color: none !important;
      box-shadow: none;
    }
  }
}