@import '../../../styles/common.styles.scss';

.quiz-container {
  @include shadow;
  position: relative;
  min-height: 60vh;
  text-align: center;
  padding: 20px;
  background-color: #fff;
  border-radius: 30px;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  animation: zoom-in-zoom-out 1s ease;
  user-select: none;

  .quiz-info {
    display: flex;
    justify-content: space-between;
    letter-spacing: 1px;
    padding: 10px;
    color: $blue-color;
    border-bottom: 1px solid #ddd;
    font-size: .9rem;
    align-items: center;

    .quiz-subject-details {
      position: absolute;
      display: flex;
      padding: 10px;
      height: 20px;
      left: 10%;
      top: -40px;
      width: 80%;
      background-color: $yellow-color;
      justify-content: center;
      z-index: 1;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      overflow: hidden;
      right: auto;
        
      .timer-section { 
        width: 5vw;
        display: inline-block;

      }
    }

    .exit-quiz {
      img {
        width: 50px;
        margin-top: -10px;
      }
    }

    .exit-quiz:hover {
      cursor: pointer;
    }
  }

  .questionNumber {
    @include shadow;
    color: #fff;
    font-size: .9rem;
    letter-spacing: 1px;
    padding: 5px 10px;
    background-color: $purple-color;
    border-radius: 5px;
  }

  .quizQuestion {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: $purple-color;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: 'Comic Neue';
    letter-spacing: 2px;
  }

  .quizImage {
    margin-top: 10px;
  }

  .quizAnswers {
    margin-top: 10px;
    animation: move-up 2s ease;
    font-weight: bold;
    font-family: 'Comic Neue';
    letter-spacing: 2px;

    .answer {
      border: 1px solid $purple-color;
      padding: 10px;
      text-align: left;
      margin: 5px 10px;
      border-radius: 20px;
      letter-spacing: 1px;
      color: $blue-color;
      cursor: pointer;
      font-weight: bold;
      font-family: 'Comic Neue';
      letter-spacing: 2px;

      img {
        width: 50%;
      }
    }

    .answer:hover {
      background-color: #fdc453;
    }



    .selectedAnswer {
      color: #ffffff;
      background-color: $purple-color;
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
      margin: 5px 10px;
      border-radius: 20px;
      letter-spacing: 1px;
      // animation: gelatine .5s ease;

      img {
        width: 50%;
      }
    }

  }

  .quiz-buttons {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    .next-prev-button {
      margin: 0px 10px;

      img {
        width: 50px;
      }
    }

    .next-prev-button:hover {
      cursor: pointer;
    }

    button {
      background-color: $purple-color;
      color: #ffffff;
      padding: 10px 12px;
      letter-spacing: 1px;
      margin: 0px 10px;
    }

    .disableButton {
      background-color: #999;
    }
  }
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .quiz-container {
    margin-top: 10px;

    .quiz-info {
      z-index: 0;

      .quiz-subject-details {
        top: -60px;
        height: 35px;
        border-radius: 10px;
      }
    }
  }
}