@import '../../styles/common.styles.scss';

.edit-profile-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;


  .back-gradient {
    background: linear-gradient(to bottom, #001540 0%, #570861 99%) !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: -999;
  }

  .corner-image {

    img {
      position: absolute;
      width: 40vw;
      right: 0;
      top: 0;
      margin-bottom: -10px;
    }
  }

  .left-corner-image {

    img {
      position: absolute;
      width: 50vw;
      left: 0;
      bottom: 0;
      margin-bottom: -10px;
    }
  }

  .sign-up-details-form {
    position: relative;

    .welcomePanel {
      text-align: center;
      margin-top: -20px;

      .welcome-message {
        margin: 40px 20px 20px 20px;
        letter-spacing: 1px;
        color: #fff;
        font-size: 2rem;
        font-weight: bold;

        div {
          font-size: 1rem;
          color: $yellow-color;
          font-weight: normal;
        }

        .welcome-free-credits {
          padding: 5px 0px;
          color: $yellow-color;
        }
      }

      Button {
        margin-top: 30px;
        background-color: $yellow-color;
        color: $purple-color;
        font-weight: bold;
      }
    }

    .basic-details-form {

      .welcome-instructions {
        letter-spacing: 1px;
        color: #fff;
        margin-top: 30px;
      }

      .formContainer {
        margin-top: 10px;
        padding-top: 0px;

        .profile-success-message {
          margin-top: 20px;
          text-align: center;
          letter-spacing: 1px;
          color: $blue-color;

        }

        .profile-success-buttons {
          display: flex;
          justify-content: center;

          .success-button {
            padding-top: 20px;
            margin: 10px 30px;

            span {
              display: inline-block;
              min-width: 150px;
              padding: 0px 10px;
              line-height: 40px;
              background: $yellow-color;
              text-align: center;
              position: relative;
              text-decoration: none;
              color: $blue-color;
              font-size: 15px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 2px;
            }

            span:before,
            span:after {
              position: absolute;
              content: "";
              transition: all .25s;
            }

            span:before {
              border-bottom: 2px solid $purple-color;
              border-left: 2px solid $purple-color;
              width: 10%;
              height: 33%;
              left: -10px;
              bottom: -10px;
            }

            span:after {
              border-top: 2px solid $purple-color;
              border-right: 2px solid $purple-color;
              width: 10%;
              height: 33%;
              top: -10px;
              right: -10px;
            }

            span:hover:before {
              width: 109%;
              height: 142%;
            }

            span:hover:after {
              width: 109%;
              height: 142%;
            }

            span:hover {
              cursor: pointer;
            }
          }

          .delay-animation {
            animation: wiggle 4s 4s infinite;

            span:hover:before {
              width: 108%;
              height: 140%;
            }

            span:hover:after {
              width: 108%;
              height: 140%;
            }
          }
        }


        img {
          width: 90%;
        }

        img:hover {
          cursor: pointer;
        }

        .leftArea {
          .profileImage {
            margin-top: 30px;
          }
        }

        .display-image-title {
          width: 100%;
          font-weight: bold;
          color: $blue-color;
          letter-spacing: 1px;
          font-size: 1rem;
          border-bottom: 1px solid #ccc;
          padding-top: 10px 0px;
          margin-bottom: 10px;
        }

        .grid-list {
          text-align: center;

          .gridImage {
            cursor: pointer;

            img {
              width: 50px;
            }
          }

          .gridImage:hover {
            background-color: #ccc;
          }
        }

      }

      .profile-success {
        margin-top: 50px;
      }
    }


  }

  .formContainer {
    margin-bottom: 30px;
    padding: 30px;
    min-height: 250px;
    animation: move-up 1s ease;


    .leftArea {
      padding-top: 10px;
      text-align: center;

      .profileImage {
        img {
          width: 50%;
        }

        .editAvatar {
          font-weight: bold;
          color: $blue-color;
          letter-spacing: 1px;
          background-color: $yellow-color;
          margin: 0px 10px;
          border-radius: 30px;
        }

        .editAvatar:hover {
          cursor: pointer;
          background-color: $purple-color;
          color: #fff;
        }
      }

      .profileData {
        font-weight: bold;
        letter-spacing: 1px;
        margin-top: 10px;
        color: $red-color;

        div {
          color: $blue-color;
          font-size: 1.5rem;
        }
      }

      .singleLine {
        display: flex;
        display: flex;
        text-align: center;
        margin-left: 50px;
        margin-right: 50px;
        padding-bottom: 30px;
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
      }
    }

    .rightArea {
      margin-top: 30px;
      font-size: .8rem;

      form {
        .formField {
          margin-bottom: 15px;
        }

        .error-text {
          color: $red-color;
          font-size: small;
        }

        .saveButton {
          text-align: right;
        }
      }
    }


  }


  .kidsProfileMobile {
    width: 100vw;
    padding: 0;
    margin-left: 0;
    margin-right: 0;

    .formContainer {
      border: none;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 5px;

      .rightArea {
        padding: 0;
      }

      .leftArea {
        padding-top: 10px;
        text-align: center;
        background-color: #ffeeff;

        .profileImage {
          img {
            width: 50%;
          }
        }
      }
    }
  }
}

.profile-background-gradient {
  background-color: none;
  @include gradient;

}