@import "../../../styles/common.styles.scss";


.contest-posters-main {

  .download-button {
    background-color: $purple-color;
    color: #fff;
    padding: 5px 10px;
    @include shadow;
    display: inline-block;
    margin-bottom: 20px;
    margin-right: 10px;
  }

  .download-button:hover {
    background-color: $yellow-color;
    color: $blue-color;
    cursor: pointer;
  }

  .contest-fb-poster {
    width: 800px;
    height: 1000px;
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    position: relative;
    z-index: 0;

    .bg-image {
      position: absolute;
      top: -50px;
      left: -50px;
      bottom: 50px;
      z-index: -1;
      height: 130%;
      padding: 0px;
      margin: 0px;
      opacity: .05;

      img {
        height: 100%;
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      font-family: 'Oswald', sans-serif;

      .top-image {
        width: 30%;

        img {
          width: 300px;
          margin-left: -50px;
          margin-top: -50px;
        }
      }

      .top-info {
        width: 50%;
        padding: 0px 30px;
        text-align: center;


        .info-text {
          font-size: 1.5rem;
          color: $purple-color;
          font-weight: bold;
          letter-spacing: 1px;
        }

        .info-subtext {
          color: #777;
          font-weight: normal;
        }

        .school-logo {
          img {
            height: 100px;
          }
        }
      }
    }

    .data-row {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      letter-spacing: 1px;
      font-weight: bold;
      margin-bottom: 50px;
    }

    .middle-row {

      margin-top: 80px;
      margin-bottom: 80px;
      display: flex;
      justify-content: space-evenly;

      .class-result {
        .class-title {
          background-color: $yellow-color;
          border-radius: 20px;
          color: $blue-color;
          padding: 5px 10px;
          font-weight: bold;
          letter-spacing: 1px;
          text-align: center;
          width: fit-content;
          margin-bottom: 20px;
        }

        .result-list {
          div {
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 5px;
            font-size: .8rem;
            text-transform: capitalize;

            img {
              width: 30px;
              vertical-align: middle;
            }
          }
        }
      }
    }

    .final-row {
      min-height: 100px;
      border-top: 1px solid #ccc;
      width: 100%;
      align-items: center;
      background-color: #fff;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
    }

    .not-partner {
      justify-content: center;
    }
  }
}