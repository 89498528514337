@import "../../../styles/common.styles.scss";

.activity-list-page {
  @include page-container;
  min-height: 100vh;
  padding-top: 60px;
  user-select: none;

  .activity-search {
    background-color: #fff;
    @include shadow;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 25px;
    .formField {
      Button {
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }
  .sendButton {
    margin-left: 10px;
  }
  .uploaded-image {
    margin-left: 20px;
    height: 10%
  }
  .control {
    margin-left: 20px;
  }
  .pagination {
    text-align: center;
  }
}
