@import '../../styles/common.styles.scss';

.result-container {
  min-height: 600px;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  .TitleSmall {
    display: flex;
    margin-top: 10px;
    // border-top: 1px solid #ddd;
    padding-top: 10px;
  }

  .questionNumber {
    color:$gray-color;
    font-weight: bold;
    font-size: 1.1rem;
    letter-spacing: 1px;
    margin-bottom: 20px;
  }

  .resultQuestion {
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    color: $purple-color;
    font-size: 1.3rem;
    font-weight: bold;
    font-family: 'Comic Neue';
    letter-spacing: 2px;
  }

  .resultImage {
    margin-top: 20px;
  }

  .resultAnswers {
    margin-top: 20px;
    width: 70vw;
    margin-left: 5vw;
    margin-top: 10px;
    font-weight: bold;
    font-family: 'Comic Neue';
    letter-spacing: 2px;

    .answer {
      border: 1px solid $purple-color;
      padding: 10px;
      text-align: left;
      margin: 5px 10px;
      border-radius: 20px;
      letter-spacing: 1px;
      color: $blue-color;
      cursor: pointer;
      font-weight: bold;
      font-family: 'Comic Neue';
      letter-spacing: 2px;

      img {
        width: 50%;
      }
    }

    .selectedAnswer {
      color: #ffffff;
      background-color: $red-color;
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
      margin: 10px 10px;
      border-radius: 20px;
      letter-spacing: 1px;
      font-weight: bold;

      img {
        width: 50%;
      }
    }

    .correctAnswer {
      background-color: $green-color;
    }
  }

  .resultButtons {
    text-align: center;
    width: 80vw;
    margin-top: 30px;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    padding-bottom: 20px;

    button {
      background-color: #004AAD;
      color: #ffffff;
    }
  }
}

// For Mobile

.result-containerMobile {
  margin-left: 0;
  margin-top: 20px;
  border: none;
  overflow-x: hidden;

  .scoreTimer {
    letter-spacing: 1px;
    font-size: .8rem;
  }

  .TitleSmall {
    letter-spacing: 1px;
    font-size: .8rem;
  }

  .questionNumber {
    font-size: .8rem;
  }

  .resultQuestion {
    font-size: 1rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .resultImage {
    img {
      width: 75%;
    }
  }

  .resultAnswers {
    margin-top: 20px;
    margin-left: 5vw;

    .answer {
      margin: 0;
    }

    .selectedAnswer {
      margin: 0;
    }
  }


}