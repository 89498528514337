@import '../../../styles/common.styles.scss';

.contest-platform-card {

  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 9px 10px rgba(86, 124, 173, .25);
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  position: relative;
  margin: 0px 0px 20px 0px;
  text-align: center;
  justify-content: flex-start;
  width: 43vw;
  @include shadow;

  .coming-soon {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: .7rem;
    letter-spacing: 1px;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
    padding: 2px 5px;
    border-radius: 5px;
  }

  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .contest-date-time {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 0px 3px;

    .contest-platform-tag {
      font-size: .8rem;
      display: flex;
      align-items: center;
      color: #333;
      padding: 3px 3px 3px 3px;

      svg {
        width: .9rem;
        height: .9rem;
        color: $blue-color;
      }
    }
  }

  .contest-details {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .contest-platform-name {
      font-size: .9rem;
      color: $purple-color;
      letter-spacing: 1px;
      padding: 5px;
      display: flex;
      justify-content: center;
    }

  }

}

.contest-platform-card:hover {
  cursor: pointer;
  animation: box-move-up .5s ease forwards;
  background-color: #fff !important;
  border: 1px solid #ccc;
}


// Media queries
@media only screen and (max-width: 1023px) {
  .contest-platform-card {
    .contest-date-time {
      .contest-platform-tag {
        font-size: .7rem;

        svg {
          width: .7rem;
          height: .7rem;
          color: $blue-color;
          display: none;
        }
      }
    }

    .contest-details {
      .contest-platform-name {
        font-size: .8rem;
      }

    }
  }
}