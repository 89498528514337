@import "../../../styles/common.styles.scss";

.user-details-container{
  @include shadow;
  margin: 20px;
  padding: 15px;
  border-top: 10px solid $purple-color;
  background-color: #fff;
  border-radius: 10px;
  .badge-section .badge-image img {
  width: 50%;
  }
  .user-details-title {
    @include main-heading;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
} 

// Media queries
@media only screen and (max-width: 767px) {
  .user-dashbaord-top-widget-card {
    padding: 5px;
    min-height: 20px;
    text-align: center;

    .top-widget-value {
      font-size: 1rem;
    }

    .top-widget-bottom {
      text-align: center;

      .top-widget-label {
        text-align: center;
        font-size: .7rem;
        width: 100%;
      }

      .top-widget-image {
        display: none;

        img {
          width: 24px;
        }
      }
    }
  }
}
