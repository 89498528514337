@import "../../../styles/common.styles.scss";

.admin-redeem-container {
  min-height: 200px;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  margin-right: 20px;
  form {
    .redeemControl {
      margin-top: 5px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .redeemLabel {
        font-size: 1rem;
        color: $purple-color;
      }

      .redeemValue {
        font-size: 1rem;
      }
    }
  }

  .redeemContainer {
    width: 100%;
    margin: 10px;
    min-height: 170px;
  }

  .redeem-list {
    margin-top: 30px;
    font-size: 0.8rem;

    .redeem-heading {
      flex-shrink: 0;
      letter-spacing: 1px;
      display: flex;
      width: 100%;

      .heading-name {
        color: $purple-color;
        margin-right: 10px;

        span {
          color: #555;
          font-weight: normal;
        }
      }
    }

    .redeem-list-row {
      letter-spacing: 1px;
      width: 100%;
      font-weight: normal;
      font-size: 0.8rem;

      .heading-name {
        color: $blue-color;
        margin-right: 10px;

        span {
          color: #555;
        }
      }
    }

    .redeem-status-form {
      margin-top: 20px;
      border-top: 1px solid #ccc;
      padding-top: 10px;
      display: flex;
      justify-content: flex-end;

      button {
        margin: 10px;
      }
    }

    .message {
      letter-spacing: 1px;
      font-weight: bold;
      color: #555;
      text-align: justify;
    }
  }

  .redeem-pagination {
    margin-top: 30px;

    .MuiPagination-ul {
      justify-content: center;
    }
  }
}

.redeem-container-mobile {
  width: 100vw;
  min-height: 500px;
  text-align: center;
  margin-top: 20px;
  margin-left: 0;
}
