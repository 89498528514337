@import '../../styles/common.styles.scss';

.quiz-container-common {
  @include shadow;
  position: relative;
  min-height: 100vh;
  text-align: center;
  background-color: #fff;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  animation: zoom-in-zoom-out 1s ease;
  user-select: none;

  .quiz-space {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;

    .quiz-info {
      display: flex;
      text-align: center;
      letter-spacing: 1px;
      color: $yellow-color;
      background-color: $primary-color;
      border-bottom-left-radius: 7vw;
      border-bottom-right-radius: 7vw;
      // border-bottom: 1px solid #ddd;
      font-size: .8rem;
      align-items: center;
      margin-bottom: 5px;

      .quiz-subject-details {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        width: 100vw;
        justify-content: center;
        align-items: center;
        padding: 3px 0px;
        z-index: 1;
        overflow: hidden;
        right: auto;
        // border-top: 1px solid $purple-color;
        // border-bottom: 1px solid $purple-color;
      }

      .quiz-question-audio {
        position: absolute;
        top: -3px;
        left: 0;
        right: 0;
        margin: auto;

        span {
          button {
            background-color: #fff !important;
            width: 30px !important;
            height: 30px !important;
            border-radius: 50px !important;
            color: #fff !important;
            font-size: 2rem;
            padding-bottom: 10px !important;
            cursor: pointer;
          }
        }
      }

      .exit-quiz {
        position: absolute;
        top: 2vw;
        right: 2vw;

        img {
          width: 30px;
        }
      }

      .exit-quiz:hover {
        cursor: pointer;
      }
    }

    .questionNumber {
      position: absolute;
      top: 2vw;
      left: 2vw;
      color: #fff;
      font-size: .8rem;
      letter-spacing: 1px;
      padding: 3px 7px;
      background-color: $purple-color;
      border-radius: 5px;
    }

    .quiz-top-section {
      min-height: 35vh;

      .quizQuestion {
        padding: 10px;
        color: $purple-color;
        font-size: 1rem;
        font-weight: bold;
        font-family: 'Comic Neue';
        letter-spacing: 2px;
      }

      .quizImage {
        margin-top: 10px;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;

        .quiz-question-animation {
          width: 50vw;
        }
      }
    }

    .quizAnswers {
      background-color: $purple-color;
      padding-top: 20px;
      animation: move-up 2s ease;
      font-family: 'Comic Neue';
      letter-spacing: 2px;
      display: flex;
      flex-direction: column;
      min-height: 50vh;
      justify-content: space-between;
      flex-wrap: wrap;

      .quiz-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        // min-height: 35vh;
        margin-top: 5vh;
        align-items: center;

      }

      .answer {
        padding: 7px 10px;
        text-align: left;
        margin-bottom: 20px;
        border-radius: 20px;
        letter-spacing: 1px;
        color: #fff;
        background-color: rgba(0,0,0,.5);
        border: 1px solid $yellow-color;
        cursor: pointer;
        font-family: 'Comic Neue';
        letter-spacing: 2px;
        max-width: 80vw;
        min-width: 35vw;
        font-size: 1.2rem;
        margin-left: 3vw;
        margin-right: 3vw;

        img {
          width: 50%;
        }
      }

      .answer:hover {
        background-color: #fdc453;
      }



      .selectedAnswer {
        padding: 7px 10px;
        text-align: left;
        margin-bottom: 20px;
        border-radius: 20px;
        letter-spacing: 1px;
        color: $primary-color;
        background-color: $yellow-color;
        border: 1px solid $primary-color;
        cursor: pointer;
        font-family: 'Comic Neue';
        letter-spacing: 2px;
        max-width: 80vw;
        min-width: 35vw;
        font-size: 1.2rem;
        margin-left: 3vw;
        margin-right: 3vw;

        img {
          width: 50%;
        }
      }

      .quiz-buttons {
        text-align: center;
        border-top: 1px solid #ddd;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 20px;
        display: flex;
        justify-content: center;
        background-color: $primary-color;

        .next-prev-button {
          margin: 0px 10px;

          img {
            width: 50px;
          }
        }

        .next-prev-button:hover {
          cursor: pointer;
        }

        button {
          background-color: $purple-color;
          color: #ffffff;
          padding: 10px 12px;
          letter-spacing: 1px;
          margin: 0px 10px;
        }

        .disableButton {
          background-color: #999;
        }
      }

    }

    .answerImage {
      justify-content: space-around;

      .answer {
        width: 35vw;
        background-color: #fff;
        margin: 10px;
        color: $primary-color;
        img{
          width: 100%;
        }
      }

      .selectedAnswer {
        width: 40vw;
        margin: 10px;
        color: $primary-color;
        background-color: $yellow-color;
        img{
          width: 100%;
        }
      }
    }


  }
}

// Media queries
@media only screen and (max-width: 767px) {

  /* For mobile phones: */
  .quiz-container-common {

    .quiz-space {
      .quiz-info {
        z-index: 0;

        .quiz-subject-details {
          top: 0px;
          height: 35px;
        }
      }
    }
  }
}