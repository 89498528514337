@import '../../../styles/common.styles.scss';

.guess-master-listing {
  text-align: center;

  .guess-master-card {
    background-color: #fff;
    margin: 30px 10px;
    border-radius: 10px;
    @include shadow;

    img {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .guess-master-name {
      font-size: 1.3rem;
      color: $purple-color;
      letter-spacing: 1px;
    }

    .guess-master-tag {
      font-size: .9rem;
      color: $gray-color;
      letter-spacing: 1px;
      padding-bottom: 10px;

      div {
        color: $red-color;
        font-weight: bold;
      }
    }

  }

  .guess-master-card:hover {
    cursor: pointer;
    animation: box-move-up .5s ease forwards;
  }
}




// Media queries
@media only screen and (max-width: 1023px) {
  .guess-master-listing {
    .guess-master-card {
      .guess-master-name {
        font-size: 1rem;
      }

      .guess-master-tag {
        font-size: .7rem;
      }
    }
  }
}