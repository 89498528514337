@import '../../../styles/common.styles.scss';

.redeem-container {
  min-height: 500px;
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  .redeem-history-title {
    font-size: 1rem;
    padding-left: 10px;
    letter-spacing: 1px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    color: #555;
    text-align: left;
  }

  .redeem-list {
    margin-top: 10px;
    font-size: .8rem;
    margin-left: 2vw;
    margin-right: 2vw;

    .redeem-heading {
      flex-shrink: 0;
      letter-spacing: 1px;
      display: flex;
      width: 100%;

      .heading-name {
        color: $purple-color;
        margin-right: 10px;

        span {
          color: #555;
          font-weight: normal;
        }
      }

    }

    .redeem-list-row {
      letter-spacing: 1px;
      width: 100%;
      font-weight: normal;
      font-size: .8rem;

      .heading-name {
        color: $blue-color;
        margin-right: 10px;

        span {
          color: #555;
        }
      }

    }

    .message {
      letter-spacing: 1px;
      font-weight: bold;
      color: #555;
      text-align: justify;
    }
  }

  .redeem-pagination {
    margin-top: 30px;

    .MuiPagination-ul {
      justify-content: center;
    }
  }

  .redeem-history-not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

    .no-redeem-message {
      letter-spacing: 1px;
      color: $gray-color;
      margin-bottom: 20px;
    }

    .redeem-history-page-top-animation {
      width: 50vw;
    }
  }
}