@import '../../styles/common.styles.scss';

.mobileMenu {
  min-height: 25vh;
  padding-top: 30px;

  .menuList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .menu-list-item {
      width: 27vw;
      text-align: center;
      font-size: .8rem;
      letter-spacing: 1px;
      margin-bottom: 30px;

      img {
        width: 50%;
      }

      .mobileMenuItem{
       .menu-animation-container{
        width: 100%;
        display: flex;
        justify-content: center;
         .menu-animation{
          width: 50%;
        }
       }
      }

      a{
        .menu-animation-container{
          width: 100%;
          display: flex;
          justify-content: center;
           .menu-animation{
            width: 50%;
          }
         }
      }
    }

    .mobileMenuText {
      color: $blue-color;
      text-decoration: none;
      margin: 0 1rem;
      letter-spacing: 1px;
      font-weight: bold;
    }
  }

  .mobileMenuTop {
    width: 100%;
    text-align: center;
    letter-spacing: 1px;
    color: #fff;
    border-bottom: 1px solid #ccc;
    background-color: $purple-color;

    img {
      width: 10vh;
      background-color: #fff;
      border-radius: 50px;
      padding: 10px;
    }

    .not-logged-in {
      font-size: .8rem;
    }
  }
}

.menu-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  img {
    width: 50px;
    margin-bottom: 10px;
  }
}