@import '../../styles/common.styles.scss';

.card-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 9px 10px rgba(86, 124, 173, .25);
  cursor: pointer;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  margin: 20px;
  padding-bottom: 10px;

  h3 {
    letter-spacing: 2px;
    margin-top: 10px;
    margin-bottom: 0px;
    color: $blue-color;
  }

  p {
    letter-spacing: 1px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: $purple-color;
    font-weight: bold;
  }

  .subject-info {
    letter-spacing: 1px;
    color: $purple-color;
    margin: 2px 0px;
  }

  img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

}

.card-container:hover {
  transform: scale(1.05);
}