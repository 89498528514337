@import '../../styles/common.styles.scss';

.quiz-grade-list {
  width: 90vw;
  margin-left: 5vw;
  margin-bottom: 50px;

  .grade-label {
    font-size: 1.3rem;
    color:$gray-color;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 50px;
    border-bottom: 4px solid #ccc;
    border-bottom-style: double;
    display: inline-block;
  }

  .grade-title {
    font-size: 1.2rem;
    letter-spacing: 1px;
    color:$gray-color;
    text-align: left;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    position: relative;
    margin-top: 50px;

    img {
      position: absolute;
      width: 60px;
      right: 20px;
      bottom: 0;
    }

    .quote-string {
      font-size: 1rem;
      font-weight: normal;
      letter-spacing: 1px;
      color:$gray-color;
      text-transform: none;
      // font-style: italic;
    }
  }


  .quiz-listing {
    text-align: center;

    .quiz-card {
      background-color: #fff;
      margin: 20px 30px 20px 0px;
      border-radius: 10px;
      @include shadow;

      img {
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .quiz-name {
        font-size: 1rem;
        color: $purple-color;
        letter-spacing: 1px;
        padding-bottom: 20px;
      }

      .quiz-tag {
        font-size: .9rem;
        color:$gray-color;
        letter-spacing: 1px;
      }

    }

    .quiz-card:hover {
      cursor: pointer;
      animation: box-move-up .5s ease forwards;
    }
  }
}