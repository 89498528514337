@import '../../styles/common.styles.scss';

.bottom-nav-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: .6rem;
  letter-spacing: 1px;
  color: #ddd;
  z-index: 999;

  .bottom-nav-list-item-icon {
    img {
      width: 20px;
    }
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    img {
      width: 65px;
    }
  }
}

.bottom-nav-list-item-isactive {
  color: #ff0;
  opacity: 1;
}