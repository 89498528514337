@import "../../../styles/common.styles.scss";

.contest-start-page-container {
  padding-top: 50px;
  min-height: 100vh;
  @include plainBackground;

  .cp-error-msg {
    color: $red-color;
    letter-spacing: 1px;
    font-weight: bold;
    text-align: center;
  }

  .contest-info-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin: 20px 20px 0px 20px;
    width: 70vw;
    margin-left: 15vw;
    border: 1px solid $purple-color;
    padding: 20px;

    .contest-image {
      text-align: center;
      border-radius: 40vw;

      img {
        max-width: 40vw;
        height: 100%;
        border-radius: 50%;
      }
    }

    .contest-details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .ext-btn {
        width: 100%;
        justify-content: space-between;
        text-align: center;

        .success-button {
          padding-top: 20px;
          margin: 10px 30px;

          span {
            display: inline-block;
            min-width: 150px;
            padding: 0px 10px;
            line-height: 40px;
            background: $yellow-color;
            text-align: center;
            position: relative;
            text-decoration: none;
            color: $black-color;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 2px;
          }

          span:before,
          span:after {
            position: absolute;
            content: "";
            transition: all 0.25s;
          }

          span:before {
            border-bottom: 2px solid $purple-color;
            border-left: 2px solid $purple-color;
            width: 10%;
            height: 33%;
            left: -10px;
            bottom: -10px;
          }

          span:after {
            border-top: 2px solid $purple-color;
            border-right: 2px solid $purple-color;
            width: 10%;
            height: 33%;
            top: -10px;
            right: -10px;
          }

          span:hover:before {
            width: 109%;
            height: 142%;
          }

          span:hover:after {
            width: 109%;
            height: 142%;
          }

          span:hover {
            cursor: pointer;
          }
        }
      }

      .contest-info {
        margin-top: 10px;
        text-align: center;

        .contest-name {
          font-size: 1.2rem;
          font-weight: bold;
          letter-spacing: 1px;
          color: $blue-color;
          display: inline-block;
        }

        .timer-section {
          width: 5vw;
          display: inline-block;
        }

        .contest-date-time {
          letter-spacing: 1px;
          font-weight: bold;
          color: #333;
          .date-time{
            color: $purple-color;
          }
        }
      }

      .success-msg {
        color: $purple-color;
        text-align: center;
        margin-top: 20px;
        letter-spacing: 1px;
        display: flex;
        justify-content: center;
        align-items: center;

        .timer-msg {
          max-width: 100px;
        }

      }

      .contest-start-button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-top: 1px solid $purple-color;
        margin-top: 20px;
        padding-top: 10px;

        .form-field {
          margin: 10px;

          .cp-dropdown {
            min-width: 200px;
            color: #000;
          }
        }
      }

      .cp-filters {
        padding: 10px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        font-size: 0.9rem;

        .cp-filter-button {
          padding: 5px 20px;
          background-color: $yellow-color;
          border-radius: 20px;
          letter-spacing: 1px;
          margin-top: 10px;
        }

        .active-grade {
          background-color: $purple-color;
          color: #fff;
        }

        .cp-filter-button:hover {
          cursor: pointer;
        }
      }
    }

    .contest-terms {
      margin-top: 30px;

      .contest-terms-header {
        width: 100%;
        padding: 10px 0px;
        background-color: $purple-color;
        text-align: center;
        letter-spacing: 1px;
        color: #fff;
        font-size: 1.1rem;
        margin-bottom: 10px;
      }

      .contest-terms-details {
        letter-spacing: 1px;
        font-size: 0.8rem;
        color: $gray-color;
        min-height: 60vh;

        .tnc-frame {
          font-family: 'Poppins', sans-serif !important;
          width: 100%;
          border: none;
          min-height: 60vh;
        }
      }
    }
  }
}

// Media queries
@media only screen and (max-width: 1023px) {
  .contest-start-page-container {
    width: 98%;
    margin-left: 0;

    .contest-info-header {
      width: 98%;
      margin-left: 0;
      border: none;
      padding: 5px;
    }
  }
}