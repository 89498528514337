@import '../../../styles/common.styles.scss';

.all-answers-container-common {
  @include shadow;
  width: 100%;
  background-color: #fff;
  animation: zoom-in-zoom-out 1s ease;
  height: 100%;
  overflow-y: auto;
  overflow-wrap: break-word;
  border-radius: 10px;
  padding-top: 70px;
  padding-bottom: 70px;

  .view-scorecard {
    text-align: center;
    letter-spacing: 1px;
    padding: 20px 0px;

    span {
      background-color: $blue-color;
      color: #fff;
      padding: 5px 10px;
      border-radius: 20px;
    }

    span:hover {
      cursor: pointer;
    }
  }


  .checkbox-control {
    text-align: center;
    color: $gray-color;
    padding: 0px 0px 9px 0px;
    border-bottom: 1px solid #ccc;
  }

  .all-answers-details {
    padding: 10px 20px 10px 10px;


    .all-answers-row {
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;
      animation: move-up 1s ease;

      .all-answers-label {
        font-size: .8rem;
        letter-spacing: 1px;
        font-weight: bold;
        color: $gray-color;
      }

      .all-answers-question {
        color: $purple-color;
        letter-spacing: 1px;
        font-weight: bold;
        margin-bottom: 10px;

        img {
          width: 25%;
        }
      }

      .all-answers-option-text {
        margin-top: 5px;
        letter-spacing: 1px;
        color: $blue-color;
        padding: 3px 10px;
        margin: 0px 5px;
        border-radius: 20px;

        img {
          width: 50%;
        }
      }


      .selected-answer {
        background-color: $red-color;
        color: #fff;
      }

      .correct-answer {
        background-color: $green-color;
        color: #fff;
      }
    }
  }
}