@import '../../../styles/common.styles.scss';

.referral {
  margin: 20px;
  text-align: center;
  font-size: 1rem;

  .referral-image {
    margin-bottom: 10px;

    img {
      width: 50%;
    }
  }

  .referral-code {
    font-size: 1.5rem;
    color: $red-color;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .referral-code-box {
    background-color: $purple-color;
    color: $yellow-color;
    font-size: 1.5rem;
    border-radius: 10px;
    display: inline-block;
    padding: 5px 7px;
    margin-top: 20px;
    letter-spacing: 1px;
    margin-bottom: 20px;

    .referral-code-tag {
      color: #fff;
      font-size: .8rem;
    }
  }

  .social-share {
    margin-bottom: 20px;
  }

  .referred-by {
    font-size: .8rem;
    margin-bottom: 10px;
  }

  .formField {
    Button {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}


// Media queries

@media only screen and (max-width: 1023px) {

  .referral {
    .referral-image {
      img {
        width: 80%;
      }
    }
  }
}